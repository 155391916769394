import image1 from "../Images/image1.png";
import image2 from "../Images/image2.png";
import image3 from "../Images/image3.png";
import image4 from "../Images/image4.png";
import image5 from "../Images/image5.png";
import image6 from "../Images/image6.png";
import image7 from "../Images/image7.png";
import image8 from "../Images/image8.png";
import image9 from "../Images/image9.png";
// import image10 from "../Images/image10.jpg";
import logo from "../Images/logo.png";

import React from 'react';
import './help.css'

const Help = () => {
  return (
    <div id="root">
      <div className="container">
        <div className="logo">
          <img src={logo} alt="logo" />
          <h1>Galacton Guide</h1>
        </div>
        <h3 className="version">Edition of 23.07.2024</h3>
        <div className="box">
          <h2 className="title">Who are we</h2>
          <p className="content">
            Galacton is a tapping game set in space. Explore various celestial objects and complete tasks to
            increase your profits. Invite friends to join the adventure and maximize your earnings. Dive into the cosmic challenge today.
          </p>
        </div>
        <div className="box">
          <h2 className="title">How to play</h2>
          <div className="d-flex">
            <p className="content">
              Tap on the Astronaut, and start collecting coins/points. All your earnings will be displayed on the
              main screen
            </p>
            <img src={image8} alt="" className="right_img img" />
          </div>
          <div className="d-flex mt-4">
            <div className="mr-4">
              <p className="content">
                When you reach ultimate levels by collecting a specific amount of coins, it results in level-ups and
                rewards that increase your energy limits.
              </p>
              <div className="responsive-table mt-4">
                <table className="table">
                  <thead>
                    <tr>
                      <th>Lvl</th>
                      <th>Lvl Name</th>
                      <th>Current Balance</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>1</td>
                      <td>Asteroid</td>
                      <td>0</td>
                    </tr>
                    <tr>
                      <td>2</td>
                      <td>Moon</td>
                      <td>1,00,001</td>
                    </tr>
                    <tr>
                      <td>3</td>
                      <td>Planet</td>
                      <td>11,00,001</td>
                    </tr>
                    <tr>
                      <td>4</td>
                      <td>Star</td>
                      <td>60,00,001</td>
                    </tr>
                    <tr>
                      <td>5</td>
                      <td>Nebula</td>
                      <td>1,60,00,001</td>
                    </tr>
                    <tr>
                      <td>6</td>
                      <td>Black Hole</td>
                      <td>5,60,00,001</td>
                    </tr>
                    <tr>
                      <td>7</td>
                      <td>Supernova</td>
                      <td>13,60,00,001</td>
                    </tr>
                    <tr>
                      <td>8</td>
                      <td>Quasar</td>
                      <td>37,60,00,001</td>
                    </tr>
                    <tr>
                      <td>9</td>
                      <td>Galaxy</td>
                      <td>85,60,00,001</td>
                    </tr>
                    <tr>
                      <td>10</td>
                      <td>Universe</td>
                      <td>2,29,60,00,001</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <img src={image9} alt="" className="right_img img img-9" />
          </div>
          <div className="d-flex earning-content">
            <p className="content">
              Earning coins is simple: you tap on the screen and get 1 tap = 1 coin
            </p>
            <img src={image6} alt="" className="right_img img" />
          </div>
          <h3 className="sub_title mt-4">Energy Booster</h3>
          <ul className="mt-4">
            <li>The User has 6 boosters available per day.</li>
            <li>When the User exhausts their tapping power and wants to continue tapping immediately, they can use
              Full energy to bypass the auto-refilling time.</li>
            <li>Once a User uses the boosters to update their energy, they will have to wait for 1 hour to use it again.</li>
          </ul>
          <div className="d-flex mt-4">
            <p className="content">
              You can also get extra coins in the earn section
            </p>
            <img src={image3} alt="" className="right_img img" />
          </div>
          <p className="content mt-4">
            In the Everyday game, you can earn extra coins from the daily reward section. The number of coins you receive increases each day, and on the 11th day, the countdown resets.
          </p>
          <p className="note">
            <span>Note:</span> Your progress will be reset and you will have to start collecting coins from scratch if you skip at least one day.
          </p>
          <div className="sideby_images">
            <img src={image2} alt="" className="img" />
            <img src={image7} alt="" className="img" />
          </div>
          <div className="mt-4">
            <div>
              <h3 className="sub_title">
                Passive Income
              </h3>
              <p className="content mt-2">
                In this game, you need to purchase resource buildings to initiate passive income. These buildings generate profit per hour, meaning you can upgrade them to continue earning even when you are not actively playing.
              </p>
              <p className="note mt-2">
                <span>Note:</span> The duration of passive income is limited to three hours following game logout. The mining then comes to an end, and you have to restart the game.
              </p>
            </div>
            <div className="sideby_images">
              <img src={image5} alt="" className="img" />
              <img src={image1} alt="" className="img" />
            </div>
          </div>
          <div className="d-flex mt-4">
            <div>
              <h2 className="title">
                Referral program
              </h2>
              <p className="content">
                In the "Friends" section, you can send an invitation link. When your friend clicks on it, their name will appear at the bottom of your friend's list.
              </p>
              <p className="content">
                As a participant in our referral program, you will receive 500 coins with a standard account or 1,000 coins if you have Telegram Premium.
              </p>
            </div>
            <img src={image4} alt="" className="right_img img" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Help;

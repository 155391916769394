import React from 'react';
import { BrowserRouter as Router, Route, Routes, NavLink } from 'react-router-dom';
import './App.css';
import Home from './Components/home';
import Booster from './Components/booster';
import Help from './Components/help';

function App() {
  return (
    <Router>
      <div className="App">
        <header className="App-header">
          <div className="App-header-left">
            <h1 className="App-heading">Galacton</h1>
          </div>
          <nav className="App-nav">
            <NavLink exact to="/" activeClassName="active-link">Home</NavLink>
            <NavLink to="/booster" activeClassName="active-link">Booster</NavLink>
            <NavLink to="/help" activeClassName="active-link">Help</NavLink>
          </nav>
        </header>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/booster" element={<Booster />} />
          <Route path="/help" element={<Help />} />
        </Routes>
        <footer className="App-footer">
          <span>© 2024 Antiersolutions. All Rights Reserved.</span>
        </footer>
      </div>
    </Router>
  );
}

export default App;
